import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  { path: 'app-login', loadChildren: './app-login/app-login.module#AppLoginPageModule' },
  { path: 'signup', loadChildren: './signup/signup.module#SignupPageModule' },
  { path: 'company-applay-loan', loadChildren: './company-applay-loan/company-applay-loan.module#CompanyApplayLoanPageModule' },
  { path: 'payment-schedule', canActivate: [AuthGuard], loadChildren: './payment-schedule/payment-schedule.module#PaymentSchedulePageModule' },
  { path: 'payment-history', loadChildren: './payment-history/payment-history.module#PaymentHistoryPageModule' },
  { path: 'notification', canActivate: [AuthGuard], loadChildren: './notification/notification.module#NotificationPageModule' },
  { path: 'faq', loadChildren: './faq/faq.module#FaqPageModule' },
  { path: 'contact', loadChildren: './contact/contact.module#ContactPageModule' },
  { path: 'dashboard', canActivate: [AuthGuard], loadChildren: './dashboard/dashboard.module#DashboardPageModule' },
  { path: 'loan-type', loadChildren: './select-loan-type/select-loan-type.module#SelectLoanTypePageModule' },
  { path: 'individual-applay-loan', loadChildren: './individual-applay-loan/individual-applay-loan.module#IndividualApplayLoanPageModule' },
  { path: 'about', loadChildren: './about/about.module#AboutPageModule' },
  { path: 'payment-code', canActivate: [AuthGuard], loadChildren: './payment-code/payment-code.module#PaymentCodePageModule' },
  { path: 'calculator', loadChildren: './calculator/calculator.module#CalculatorPageModule' },
  { path: 'my-profile', loadChildren: './my-profile/my-profile.module#MyProfilePageModule' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
