import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingController, ToastController, AlertController, NavController } from '@ionic/angular';
import { File } from "@ionic-native/file/ngx";
import { FileTransfer, FileTransferObject } from "@ionic-native/file-transfer/ngx";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { Router } from "@angular/router";
import { catchError } from 'rxjs/operators'; 

@Injectable({
  providedIn: 'root'
})
export class AppService {
  baseUrl = environment.url;
  baseUrl1 = environment.url1;
  
  fileTransfer: FileTransferObject;

  constructor(
    private http: HttpClient,
    public loadingController: LoadingController,
    private fileOpener: FileOpener,
    private transfer: FileTransfer,
    private file: File,
    public toastCtrl: ToastController,
    public alertController: AlertController,
    public router: Router,
    public navCtrl: NavController
      ) { }

  appLogin(data: any, endpoint: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.post(this.baseUrl + endpoint, data, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
        catchError(err => {
          setTimeout(() => {
              this.hideLoading();
            }, 500);
          if(Array.isArray(err.error)){
            
            console.log('Handling error locally and rethrowing it...', err);
            return this.presentAlertRelogin('Error',err.error[0],'Please relogin to continue');
          }else{
            if(err.statusText=="Internal Server Error"){
              return this.toast('Cannot retrieve data, try again later');
            }else{
              return this.presentAlertReloadPage('Error', 'Cannot retrieve data'+this.baseUrl + endpoint,'Please reload page to continue');
            }
            
          }
            
       }
    ));
  }
  myinfo(data: any, endpoint: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.post(this.baseUrl1 + endpoint, data, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
        catchError(err => {
          if(Array.isArray(err.error)){
            setTimeout(() => {
              this.hideLoading();
            }, 500);
            console.log('Handling error locally and rethrowing it...', err);
            return this.presentAlertRelogin('Error',err.error[0],'Please relogin to continue');
          }else{
            return this.presentAlertReloadPage('Error', 'Cannot retrieve data','Please reload page to continue');
          }
            
       }
    ));
  }
  appPost(data: any, endpoint: any): Observable<any> {
    const isLogin1 = JSON.parse(localStorage.getItem('a_c_uid'));
    if(isLogin1)
    {
      var isLogin = isLogin1;
    }
    else if(JSON.parse(localStorage.getItem('tempdata'))){
      var isLogin = JSON.parse(localStorage.getItem('tempdata'));
    }
    if (isLogin) {
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      });
      return this.http.post(this.baseUrl + endpoint, { ...data, token: isLogin.token, is_token: 1 }, {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          setTimeout(() => {
              this.hideLoading();
            }, 500);
          if(Array.isArray(err.error)){
            
            console.log('Handling error locally and rethrowing it...', err);
            return this.presentAlertRelogin('Error',err.error[0],'Please relogin to continue');
          }else{

            return this.presentAlertReloadPage('Error', 'Cannot retrieve data','Please reload page to continue');
          }
            
       }
    ));
    } else {
      return;
    }
  }


  postUrlEncoded(data: any, url: any): Observable<any> {
    return this.http.post(this.baseUrl + url, data).pipe(
        catchError(err => {

          setTimeout(() => {
              this.hideLoading();
            }, 500);
          if(Array.isArray(err.error)){
            
            console.log('Handling error locally and rethrowing it...', err);
            return this.presentAlertRelogin('Error',err.error[0],'Please relogin to continue');
          }else{
            return this.presentAlertReloadPage('Error', 'Cannot retrieve data','Please reload page to continue');
          }
        })
    );
  }

  async showLoading() {
    const loading = await this.loadingController.create({
      spinner: 'circles',
      message: 'Please wait...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    return await loading.present();
  }

  async hideLoading() {
    const loading = await this.loadingController;
    return await loading.dismiss();
  }

  isLogin() {
    const isLogin = JSON.parse(localStorage.getItem('a_c_uid'));
    if (isLogin !== null) {
      if (isLogin.token) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  getPdf(url) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        responseType: 'blob',
        Accept: 'application/pdf',
        observe: 'response'
      })
    };
    return this.http
      .get(url, httpOptions);
  }

  download(url: string, title: string, type) {
    this.showLoading();
    setTimeout(() => {
      window.open(url, ' ', 'location=yes');
      this.hideLoading();
    }, 500);
  /*   this.fileTransfer = this.transfer.create();
    this.fileTransfer.download(url, this.file.externalApplicationStorageDirectory + title + '.' + type).then(entry => {
        console.log('download complete: ' + entry.toURL());
        console.log('file', this.file.dataDirectory + title + type)
        this.file.writeFile(this.file.externalApplicationStorageDirectory, 'acredit' + '.pdf', entry.file, {
          replace: true
        });
        this.hideLoading();
        window.open(url, '_self');
      }); */
  }
  async toast(msg: string, ) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      // duration: 2000
    });
    toast.present();
  }

 async presentAlertRelogin(title, subtitle, msg) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: title,
      subHeader: subtitle,
      message: msg,
      buttons: [{
          text: 'Okay',
          handler: () => {
            this.logout();
          }
        }]
    });

    await alert.present();
  }
  async presentAlertReloadPage(title, subtitle, msg) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: title,
      subHeader: subtitle,
      message: msg,
      buttons: [{
          text: 'Okay',
          handler: () => {
            // this.navCtrl.pop();
            this.router.navigate(['/dashboard']);
          }
        }]
    });

    await alert.present();
  }

  logout() {
    if(localStorage.getItem('a_c_uid'))
      localStorage.removeItem('a_c_uid');
    /*if(localStorage.getItem('loanstype'))
      localStorage.removeItem('loanstype');*/
    //this.user = null;
    this.router.navigate(['/home']);
  }
}
