import { Component, ChangeDetectorRef } from '@angular/core';

import { Platform, AlertController, NavController, MenuController, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppService } from './_service/app.service';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
//import { FCM } from '@ionic-native/fcm/ngx';
import { Router } from '@angular/router';
import {InAppBrowser, InAppBrowserOptions,InAppBrowserEvent} from '@ionic-native/in-app-browser/ngx';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  docList: any[] = [];
  fileTransfer: any;
  doc: any;
  user: any = {};
  setUrl = false;

  //20230307 - Inactive auto-logout
  nextIdleLogoutTimestamp: any;
  maxIdleMinutes: any = 20; //Set max inactive time here (in minutes)
  idleCheckInterval: any;


  constructor(
    private inAppBrowser: InAppBrowser,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private appService: AppService,
    private fileOpener: FileOpener,
    private transfer: FileTransfer,
    private file: File,
    private alertController: AlertController,
    //private fcm: FCM,
    private router: Router,
    private navCtrl: NavController,
    private menuCtrl: MenuController,
    private cd: ChangeDetectorRef,
    private events: Events
  ) {
    this.initializeApp();
    this.getDocument();

    //20230307 - Inactive auto-logout
    this.updateLastActiveTimestamp();
  }

  initializeApp() {
    this.events.subscribe('user:signedIn', (userEventData) => {
        this.user = userEventData;
	      this.getDocument();

        //20230307 - Inactive auto-logout
        this.startIdleCheckInterval(); //Start idleCheckInterval after successful login
        
    });

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.notification();
      this.user = JSON.parse(localStorage.getItem('a_c_uid'));
      //console.log(this.user);
      if (this.user) {
          if (!this.setUrl) {
             //this.navCtrl.navigateRoot('/dashboard');
          } else {
              this.setUrl = false;
          }
          /*console.log('Tabs ROOT ');
          setTimeout(() => {
              this.splashScreen.hide();
          }, 1000);*/

          //20230307 - Inactive auto-logout
          this.startIdleCheckInterval();
      } else {
          //console.log('LOGIN ROOT');
          //this.navCtrl.navigateRoot('/home');
          /*setTimeout(() => {
              this.splashScreen.hide();
          }, 1000);*/
      }
    });

   
  }

  //20230307 - Inactive auto-logout
  updateLastActiveTimestamp(){
    // this.lastActiveTimestamp = moment();
    this.nextIdleLogoutTimestamp = moment().add(this.maxIdleMinutes,'minutes');
    console.log("this.maxIdleMinutes: " + this.maxIdleMinutes);
    console.log("nextIdleLogoutTimestamp: " + this.nextIdleLogoutTimestamp.format("HH:mm:ss"));
  }

  startIdleCheckInterval(){
    console.log("startIdleCheckInterval");
    var _that = this;
    if(!_that.idleCheckInterval){
      _that.idleCheckInterval = setInterval(function(){
        var _now = moment();
        if(_now > _that.nextIdleLogoutTimestamp){
          console.log("Inactive logout");
          _that.logout();
          _that.removeIdleCheckInterval();
        }else{
          //console.log("not yet logout");
        }
      },2000)
    }
  }

  removeIdleCheckInterval(){
    console.log("removeIdleCheckInterval");
    clearInterval(this.idleCheckInterval);
    this.idleCheckInterval = null;
  }
  //20230307 - Inactive auto-logout

  onMenuOpen() {
    //console.log('onMenu open called');
    if (this.user !== null) {
      var x=document.getElementById('sideDrwer');
    
    // if (x.style.display === "block") {
    //   x.style.display = "";
    // } else{
    //   x.style.display = "block";
    // }
    //   this.user = isUser;
    }
    this.cd.detectChanges();
  }

  onMenuClose(){
    //console.log('close menu');
    // var x=document.getElementById('sideDrwer');
    
    // if (x.style.display === "block") {
    //   x.style.display = "";
    // }
  }

  opendocument(url:any)
  {
    // console.log('this.p;latform=',this.platform);
    const browser = this.inAppBrowser.create(url, '_system');
    // console.log(browser,'browser');
    // browser.on('exit').subscribe(event => { 
    //   console.log("exit event called=", event);
    //  // this.initializeApp();
    // }); 
  }
  
  getDocument() {
    this.appService.appLogin({}, 'getAdminDocument')
      .subscribe(response => {
        //console.log(response);
        if (response.body.status) {
          localStorage.setItem('loanstype', JSON.stringify(response.body.loan_types));
          this.docList = response.body.data;
        }
      });
  }

  

  notification() {
    /*this.fcm.subscribeToTopic('marketing');

    this.fcm.getToken().then(token => {
      console.log("Token", token);
      localStorage.setItem('ac_nf_token', token);
    });

    this.fcm.onNotification().subscribe(data => {
      if (data.wasTapped) {
        this.setUrl = true;
        
        console.log("Received in background");
      } else {
        this.appService.toast(data.body);
        console.log("Received in foreground");
      };
    });

    this.fcm.onTokenRefresh().subscribe(token => {
      console.log("Token", token);
      localStorage.setItem('ac_nf_token', token);
    });

    this.fcm.unsubscribeFromTopic('marketing');*/
  }

  downloadDoc(doc: Document) {
    this.doc = doc;
    const type = this.doc.url.split('.');
    var name=this.doc.title;

    //console.log(this.doc.url,'type');
    //console.log(this.doc.title,'type');
    //console.log(type[type.length - 1],'type');

    this.appService.download(this.doc.url, this.doc.title, type[type.length - 1]);
    /*  this.doc = doc;
     const fileTransfer: FileTransferObject = this.transfer.create();
     fileTransfer.download(this.doc.url, this.file.externalRootDirectory + 'acredit_pdf' + '.pdf').then((entry) => {
       this.file.writeFile(this.file.externalRootDirectory, 'acredit' + '.pdf', entry.file, {
         replace: true
       });
       console.log('file download response', JSON.stringify(entry));
       this.fileOpener.open(
         entry.toInternalURL(),
         'application/pdf'
       ).then((res) => {
       }).catch(err => {
         console.log('open error');
       });
     })
       .catch((err) => {
         console.log('error in file download', err);
       }); */
    /* this.file.createDir(this.file.externalRootDirectory, 'my_downloads', false).then(response => {
      console.log('Directory created', response);

    }).catch(err => {
      console.log('Could not create directory "my_downloads" ', err);
    }); */
  }

  async presentAlert(sms: any) {
    const alert = await this.alertController.create({
      header: 'Terms & Conditions',
      message: sms,
      cssClass: ['alert_box', 'text-dark'],
      buttons: ['Close']
    });
    await alert.present();
  }

  logout() {
    if(localStorage.getItem('a_c_uid'))
      localStorage.removeItem('a_c_uid');
    /*if(localStorage.getItem('loanstype'))
      localStorage.removeItem('loanstype');*/
    this.user = null;

    localStorage.removeItem('formFieldsListWithData');
    localStorage.removeItem('getFieldsCalled');

    this.router.navigate(['/home']);


    this.removeIdleCheckInterval() //20230307 - Inactive auto-logout
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      // header: 'Confirm!',
      message: '<strong>Are you sure you want to logout?</strong>',
      buttons: [
        {
          text: 'CANCEL',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'LOGOUT',
          handler: () => {
            this.logout();
          }
        }
      ]
    });

    await alert.present();
  }

}

